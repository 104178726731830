const theme = {
  colors: {
    primary: '#fff',
    secondary: '#e4cdd8',
    dark: '#000',
    touch: '#f36f54',
    grey: '#f1f1f1',
    sandyLight: '#cca57e',
    sandyDark: '#B7916C',
  },

  media: {
    mobile: `(min-width: 375px)`,
    smallTablet: `(min-width: 667px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1024px)`,
    desktop: `(min-width: 1440px)`,
    bigDesktop: `(min-width: 1800px)`,
  },
};

export default theme;
