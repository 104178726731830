import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '../../../.cache/gatsby-browser-entry';

const StyledIndex = styled.div`
  p {
    margin-bottom: 10px;
  }
`;

const Index = ({ className }) => (
  <StyledIndex className={className}>
    <div>
      <Link to="/">Strona startowa</Link>
    </div>
  </StyledIndex>
);

Index.propTypes = {
  className: propTypes.string,
};

Index.defaultProps = {
  className: '',
};

export default Index;
