import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { ImSpinner2 } from 'react-icons/im';

const StyledButton = styled.button`
  border-radius: 20px;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  text-transform: uppercase;

  ${({ theme: { colors }, light }) =>
    light
      ? css`
          background: ${colors.primary};
          color: ${colors.dark};
          padding: 15px 35px;
          font-size: 13px;
        `
      : css`
          background: ${colors.touch};
          color: ${colors.primary};
          padding: 12px 20px;
          font-size: 0.7em;
          @media ${({ theme: { media } }) => media.tablet} {
            font-size: 13px;
          }
        `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}

  svg {
    color: ${({ theme }) => theme.colors.primary};
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({ className, text, light, isLoading, disabled, ...props }) => (
  <StyledButton
    className={className}
    light={light}
    disabled={disabled}
    {...props}
  >
    {isLoading ? <ImSpinner2 /> : text}
  </StyledButton>
);

Button.propTypes = {
  className: propTypes.string,
  text: propTypes.string.isRequired,
  light: propTypes.bool,
  type: propTypes.string,
  onClick: propTypes.func,
  isLoading: propTypes.bool,
  disabled: propTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: 'button',
  light: false,
  onClick: null,
  isLoading: false,
  disabled: false,
};

export default Button;
