import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import typo from '../../styles/typography';
import Button from '../atoms/Button';
import PageLogo from '../atoms/PageLogo';
import { Link } from 'gatsby';

const StyledNavbar = styled.nav`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #243349;

  li:not(li:last-of-type) {
    & {
      display: none;
    }
  }

  transform: ${({ visible }) =>
    visible ? 'translateY(0%)' : 'translateY(-100%)'};

  transition: transform 0.3s ease-in-out;

  @media ${({ theme }) => theme.media.laptop} {
    transform: translateY(0%);
    padding: 20px;
    padding-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
    }

    li:not(li:last-of-type) {
      & {
        display: block;
        margin-right: 30px;
      }

      a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        font-family: ${typo.lato}, Arial;
        font-size: 14px;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Navbar = () => {
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const currScrollPosition = window.scrollY;

    const scrollingDown = currScrollPosition > prevScrollPos;
    const lowerThan = currScrollPosition > 40;

    if (scrollingDown && lowerThan) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currScrollPosition);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, visible, prevScrollPos]);

  return (
    <StyledNavbar visible={visible}>
      <PageLogo />
      <ul>
        <li>
          <a>
          <Link to="/">STRONA GŁÓWNA</Link>
          </a>
        </li>
        <li>
          <a>
            <Link to="/statute">REGULAMIN/POLITYKA PRYWATNOŚCI</Link>
          </a>
        </li>
        <li>
          <Link to="/#Ksiazka">
            O KSIĄŻCE
          </Link>
        </li>
        <li>
            <Link to ="/#Autor">O AUTORZE
            </Link>
        </li>
        <li>
          <Link to="/#Zamowienie">
            <Button text={'Zamów online'}/>
          </Link>
        </li>
      </ul>
    </StyledNavbar>
  );
};

export default Navbar;
