import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '../../../.cache/gatsby-browser-entry';

const StyledStatute = styled.div`
  p {
    margin-bottom: 10px;
  }
`;

const Statute = ({ className }) => (
  <StyledStatute className={className}>
    <div>
      <Link to="/statute">Regulamin/polityka prywatności</Link>
    </div>
  </StyledStatute>
);

Statute.propTypes = {
  className: propTypes.string,
};

Statute.defaultProps = {
  className: '',
};

export default Statute;
