import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledSocials = styled.div`
  p {
    margin-bottom: 10px;
  }
`;

const Socials = ({ className }) => (
  <StyledSocials className={className}>
    <p>Śledź nas w social mediach:</p>
    <div>
      <a href="">Facebook</a>
      <br />
      <a href="">Instagram</a>
      <br />
      <a href="">Twitter</a>
      <br />
    </div>
  </StyledSocials>
);

Socials.propTypes = {
  className: propTypes.string,
};

Socials.defaultProps = {
  className: '',
};

export default Socials;
