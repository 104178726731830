import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledCompanyInfo = styled.div`
  p {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const CompanyInfo = ({ className }) => (
  <StyledCompanyInfo className={className}>
    <p>
      Pitagoras Tomasz Drzewiecki <br />
      ul. Kolejowa 4/4, 64-200 Wolsztyn <br />
      NIP: 7842352708
      REGON: 301960891
    </p>
    <p>
      Kontakt: <br />
      <a href="mailto:kontakt@szumstepuipustyni.pl">kontakt@szumstepuipustyni.pl</a>
    </p>
  </StyledCompanyInfo>
);

CompanyInfo.propTypes = {
  className: propTypes.string,
};

CompanyInfo.defaultProps = {
  className: '',
};

export default CompanyInfo;
