import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledUseTerms = styled.div`
  & > p {
    margin-bottom: 30px;
  }
  div {
    display: flex;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      p {
        margin-bottom: 5px;
      }
    }
  }

  .last-child {
    margin-left: 20px;
  }

  @media ${({ theme: { media } }) => media.mobile} {
    .last-child {
      margin-left: 50px;
    }
  }

  @media ${({ theme: { media } }) => media.tablet} {
    .last-child {
      margin-left: 0px;
    }

    div {
      justify-content: space-between;
    }
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 50px;
`;

const UseTerms = ({ className }) => {
  const { webgo, tcies, dotpay } = useStaticQuery(graphql`
    {
      webgo: file(relativePath: { eq: "logo-webgo@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)" }
          )
        }
      }

      tcies: file(relativePath: { eq: "tciesielski.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)" }
          )
        }
      }
      
      dotpay: file(relativePath: { eq: "dotpay.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)" }
          )
        }
      }
    }
  `);
  return (
    <StyledUseTerms className={className}>
      <p>
        Wszelkie prawa zastrzeżone.
        <br />
        Zdjęcia są własnością Seweryna Błasiaka.
      </p>
      <div>
        <div>
          <p>Płatności obsługuje:</p>
          <a href="https://dotpay.pl/">
            <StyledGatsbyImage
                style={{ width: '110px' }}
                alt="logo-dotpay"
                image={getImage(dotpay.childImageSharp)}
            />
          </a>
        </div>
        <div>
          <p>Projekt graficzny strony:</p>
          <a href="https://webgo.dev/">
            <StyledGatsbyImage
              style={{ width: '45px' }}
              alt="logo-webgo"
              image={getImage(webgo.childImageSharp)}
            />
          </a>
        </div>
        <div className="last-child">
          <p>Wykonanie:</p>
          <a href="https://tciesielski.pl/">
            <StyledGatsbyImage
              alt="logo-tciesielski"
              style={{ width: '25px' }}
              image={getImage(tcies.childImageSharp)}
            />
          </a>
        </div>
      </div>
    </StyledUseTerms>
  );
};

UseTerms.propTypes = {
  className: propTypes.string,
};

UseTerms.defaultProps = {
  className: '',
};

export default UseTerms;
