import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 160px;
  cursor: pointer;

  @media ${({ theme }) => theme.media.mobile} {
    width: 180px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    width: 200px;
  }
`;

const PageLogo = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "logo-szum-stepu-i-pustyni.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: {
              color: "#e4cdd8"
              background: "rgba(0, 0, 0, 0)"
            }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <Link to="/#Header">
    <StyledGatsbyImage
      alt="logo-szum-stepu-i-pustyni"
      image={getImage(data.childImageSharp)}
    />
    </Link>);
};

export default PageLogo;
