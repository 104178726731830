import { createGlobalStyle } from 'styled-components';
import typo from './typography';

const GlobalStyle = createGlobalStyle`
  body, html, 
  h1, h2, h3, h4, h5, h6, 
  p, button, input, label, ul, li {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  html {
    /* to avoid taking scrollbar width to vw */
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    position: relative;
    overflow: hidden;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  ul, li {
    list-style: none;
  }

  /*            */
  /* TYPOGRAPHY */
  /*            */

  body {
    font-size: 14px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-family: ${typo.lora}, Georgia;
    color: ${({ theme }) => theme.colors.primary};
  }

  li, 
  li > p, 
  li > div, 
  li > span {
    font-weight: 400;
    font-family: ${typo.lora}, Georgia;
    color: ${({ theme }) => theme.colors.primary};
  }

  p, button, input, label {
    font-family: ${typo.lato}, Arial;
    color: ${({ theme }) => theme.colors.primary};
  }

  button, input, select {
    font-weight: 700;
  }

  label {
    font-weight: 300;
  }


  @media ${({ theme }) => theme.media.mobile} {
    body {
      font-size: 16px;
    }
  }

  @media ${({ theme }) => theme.media.tablet} {
    body {
      font-size: 18px;
    }
  }
`;

export default GlobalStyle;
